* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
}

body {
  background-color: #121212;
  color: #ffffff;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  max-width: 1360px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 1.5rem;
  justify-content: space-between;
}
