.main {
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.main__image-container {
  height: 70vh;
  width: 100%;
  max-width: 100%;
  position: relative;
  border-radius: 4px;
}

.main__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
  position: relative;
  z-index: 2;
}

.main__image-container::before {
  content: "";
  display: block;
  background-image: inherit;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 102%;
  height: 102%;
  position: absolute;
  top: -1%;
  left: -1%;
  filter: blur(12px);
  z-index: 1;
}

.main__thumbnails {
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1vh;
}

.main__thumbnail,
.main__thumbnail_icon {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  margin: 0 5px;
}

.main__thumbnail--active {
  width: 72px;
  height: 72px;
}

.main__button {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main__thumbnail_icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
